export const palabras = [
    {
        "tipo": "cantidad",
        "name": "dijo",
        "singular": "dijo",
        "plural": "dijeron",
    },
    {
        "tipo": "cantidad",
        "name": "mayor",
        "singular": "mayor",
        "plural": "mayores",
    },
    {
        "tipo": "cantidad",
        "name": "denominara",
        "singular": "denominará",
        "plural": "denominarán",
    }, 
    {
        "tipo": "cantidad",
        "name": "numero",
        "singular": "número",
        "plural": "números",
    },
    {
        "tipo": "cantidad",
        "name": "cedula",
        "singular": "cédula",
        "plural": "cédulas",
    },
    {
        "tipo": "cantidad",
        "name": "la",
        "singular": "la",
        "plural": "las",
    },
    {
        "tipo": "cantidad",
        "name": "deja",
        "singular": "deja",
        "plural": "dejan",
    },
    {
        "tipo": "cantidad",
        "name": "conoce",
        "singular": "conoce",
        "plural": "conocen",
    },
    {  
        "tipo": "cantidad",
        "name": "acepta",
        "singular": "acepta",
        "plural": "aceptan",
    },
    {
        "tipo": "cantidad",
        "name": "debera",
        "singular": "deberá",
        "plural": "deberán",
    },
    {
        "tipo": "cantidad",
        "name": "manifiesta",
        "singular": "manifiesta",
        "plural": "manifiestan",
    },
    {
        "tipo": "cantidad",
        "name": "esta",
        "singular": "esta",
        "plural": "están",
    },
    {
        "tipo": "cantidad",
        "name": "decidiese",
        "singular": "decidiese",
        "plural": "decidiesen",
    },
    {
        "tipo": "cantidad",
        "name": "quien",
        "singular": "quien",
        "plural": "quienes",
    },
    {
        "tipo": "cantidad",
        "name": "reciba",
        "singular": "reciba",
        "plural": "reciban",
    },
    {
        "tipo": "cantidad",
        "name": "obliga",
        "singular": "obliga",
        "plural": "obligan",
    },
    {
        "tipo": "cantidad",
        "name": "haga",
        "singular": "haga",
        "plural": "hagan",
    },
    {
        "tipo": "cantidad",
        "name": "sujeto",
        "singular": "sujeto",
        "plural": "sujetos",
    },
    {
        "tipo": "cantidad",
        "name": "adquiere",
        "singular": "adquiere",
        "plural": "adquieren",
    },
    {
        "tipo": "cantidad",
        "name": "queda",
        "singular": "queda",
        "plural": "quedan",
    },
    {
        "tipo": "cantidad",
        "name": "pago",
        "singular": "pagó",
        "plural": "pagaron",
    },
    {
        "tipo": "cantidad",
        "name": "pagara",
        "singular": "pagará",
        "plural": "pagarán",
    },
    {
        "tipo": "cantidad",
        "name": "declara",
        "singular": "declara",
        "plural": "declaran",
    },
    {
        "tipo": "cantidad",
        "name": "constituye",
        "singular": "constituye",
        "plural": "constituyen",
    },
    {
        "tipo": "cantidad",
        "name": "autoriza",
        "singular": "autoriza",
        "plural": "autorizan",
    },
    {
        "tipo": "cantidad",
        "name": "hace",
        "singular": "hace",
        "plural": "hacen",
    },
    {
        "tipo": "cantidad",
        "name": "ha",
        "singular": "ha",
        "plural": "han",
    },
    {
        "tipo": "cantidad",
        "name": "tiene",
        "singular": "tiene",
        "plural": "tienen",
    },
    {
        "tipo": "cantidad",
        "name": "corresponda",
        "singular": "corresponda",
        "plural": "correspondan",
    },
    {
        "tipo": "cantidad",
        "name": "le",
        "singular": "le",
        "plural": "les",
    },
    {
        "tipo": "cantidad",
        "name": "ratifica",
        "singular": "ratifica",
        "plural": "ratifican",
    },
    {
        "tipo": "cantidad",
        "name": "renuncia",
        "singular": "renuncia",
        "plural": "renuncian",
    },
    {
        "tipo": "cantidad",
        "name": "asumira",
        "singular": "asumirá",
        "plural": "asumirán",
    },
    {
        "tipo": "cantidad",
        "name": "sufragara",
        "singular": "sufragará",
        "plural": "sufragarán",
    },
    {
        "tipo": "cantidad",
        "name": "atendera",
        "singular": "atenderá",
        "plural": "atenderán",
    },
    {
        "tipo": "cantidad",
        "name": "permite",
        "singular": "permite",
        "plural": "permiten",
    },
    {
        "tipo": "cantidad",
        "name": "libera",
        "singular": "libera",
        "plural": "liberan",
    },
    {
        "tipo": "cantidad",
        "name": "llegare",
        "singular": "llegare",
        "plural": "llegaren",
    },
    {
        "tipo": "cantidad",
        "name": "tuviere",
        "singular": "tuviere",
        "plural": "tuvieren",
    },
    {
        "tipo": "cantidad",
        "name": "llamara",
        "singular": "llamará",
        "plural": "llamarán",
    },
    {
        "tipo": "cantidad",
        "name": "tenga",
        "singular": "tenga",
        "plural": "tengan",
    },
        {
        "tipo": "cantidad",
        "name": "llegue",
        "singular": "llegue",
        "plural": "lleguen",
    },
    {
        "tipo": "cantidad",
        "name": "figure",
        "singular": "figure",
        "plural": "figuren",
    },
    {
        "tipo": "cantidad",
        "name": "comparecio",
        "singular": "compareció",
        "plural": "comparecieron",
    },
    {
        "tipo": "cantidad",
        "name": "compromete",
        "singular": "compromete",
        "plural": "comprometen",
    },
    {
        "tipo": "cantidad",
        "name": "pacta",
        "singular": "pacta",
        "plural": "pactan",
    },
    {
        "tipo": "cantidad",
        "name": "hubiere",
        "singular": "hubiere",
        "plural": "hubieren",
    },
    {
        "tipo": "cantidad",
        "name": "remite",
        "singular": "remite",
        "plural": "remiten",
    },
    {
        "tipo": "cantidad",
        "name": "realiza",
        "singular": "realiza",
        "plural": "realizan",
    },
    {
        "tipo": "cantidad",
        "name": "cambia",
        "singular": "cambia",
        "plural": "cambian",
    },
    {
        "tipo": "cantidad",
        "name": "cumple",
        "singular": "cumple",
        "plural": "cumplen",
    },
    {
        "tipo": "cantidad",
        "name": "abandona",
        "singular": "abandona",
        "plural": "abandonan",
    },
    {
        "tipo": "cantidad",
        "name": "obstaculiza",
        "singular": "obstaculiza",
        "plural": "obstaculizan",
    },
    {
        "tipo": "cantidad",
        "name": "impide",
        "singular": "impide",
        "plural": "impiden",
    },
    {
        "tipo": "cantidad",
        "name": "niega",
        "singular": "niega",
        "plural": "niegan",
    },
    {
        "tipo": "cantidad",
        "name": "declarara",
        "singular": "declarará",
        "plural": "declararán",
    },
    {
        "tipo": "cantidad",
        "name": "reconocera",
        "singular": "reconocerá",
        "plural": "reconocerán",
    },
    {
        "tipo": "cantidad",
        "name": "da",
        "singular": "da",
        "plural": "dan",
    },
    {
        "tipo": "cantidad",
        "name": "deba",
        "singular": "deba",
        "plural": "deban",
    },
    {
        "tipo": "cantidad",
        "name": "cumpla",
        "singular": "cumpla",
        "plural": "cumplan",
    },
    {
        "tipo": "cantidad",
        "name": "concederle",
        "singular": "concederle",
        "plural": "concederles",
    },
    {
        "tipo": "cantidad",
        "name": "fallece",
        "singular": "fallece",
        "plural": "fallecen",
    },
    {
        "tipo": "cantidad",
        "name": "conviene",
        "singular": "conviene",
        "plural": "convienen",
    },
    {
        "tipo": "cantidad",
        "name": "confiere",
        "singular": "confiere",
        "plural": "confieren",
    },
    {
        "tipo": "cantidad",
        "name": "su",
        "singular": "su",
        "plural": "sus",
    },
    {
        "tipo": "cantidad",
        "name": "nombre",
        "singular": "nombre",
        "plural": "nombres",
    },
    {
        "tipo": "cantidad",
        "name": "otorga",
        "singular": "otorga",
        "plural": "otorgan",
    },
    {
        "tipo": "cantidad",
        "name": "suministre",
        "singular": "suministre",
        "plural": "suministren",
    },
    {
        "tipo": "cantidad",
        "name": "documento",
        "singular": "documento",
        "plural": "documentos",
    },
    {
        "tipo": "cantidad",
        "name": "adquiera",
        "singular": "adquiera",
        "plural": "adquieran",
    },
    {
        "tipo": "cantidad",
        "name": "posea",
        "singular": "posea",
        "plural": "posean",
    },
    {
        "tipo": "cantidad",
        "name": "efectue",
        "singular": "efectúe",
        "plural": "efectúen",
    },
    {
        "tipo": "cantidad",
        "name": "garantiza",
        "singular": "garantiza",
        "plural": "garantizan",
    },
    {
        "tipo": "cantidad",
        "name": "encuentra",
        "singular": "encuentra",
        "plural": "encuentran",
    },
    {
        "tipo": "cantidad",
        "name": "envuelto",
        "singular": "envuelto",
        "plural": "envueltos",
    },
    {
        "tipo": "cantidad",
        "name": "cumplira",
        "singular": "cumplirá",
        "plural": "cumplirán",
    },
    {
        "tipo": "cantidad",
        "name": "presentara",
        "singular": "presentará",
        "plural": "presentarán",
    },
    {
        "tipo": "cantidad",
        "name": "mantendra",
        "singular": "mantendrá",
        "plural": "mantendrán",
    },
    {
        "tipo": "cantidad",
        "name": "administra",
        "singular": "administra",
        "plural": "administran",
    },
    {
        "tipo": "cantidad",
        "name": "controla",
        "singular": "controla",
        "plural": "controlan",
    },
    {
        "tipo": "cantidad",
        "name": "hara",
        "singular": "hará",
        "plural": "harán",
    },
    {
        "tipo": "cantidad",
        "name": "realice",
        "singular": "realice",
        "plural": "realicen",
    },
    {
        "tipo": "cantidad",
        "name": "suscriba",
        "singular": "suscriba",
        "plural": "suscriban",
    },
    {
        "tipo": "cantidad",
        "name": "cumpliere",
        "singular": "cumpliere",
        "plural": "cumplieren",
    },
    {
        "tipo": "cantidad",
        "name": "presenta",
        "singular": "presenta",
        "plural": "presentan",
    },
    {
        "tipo": "cantidad",
        "name": "efectua",
        "singular": "efectúa",
        "plural": "efectúan",
    },
    {
        "tipo": "cantidad",
        "name": "asume",
        "singular": "asume",
        "plural": "asumen",
    },
    {
        "tipo": "cantidad",
        "name": "cliente",
        "singular": "cliente",
        "plural": "clientes",
    },
    {
        "tipo": "cantidad",
        "name": "puede",
        "singular": "puede",
        "plural": "pueden",
    },
    {
        "tipo": "cantidad",
        "name": "podra",
        "singular": "podrá",
        "plural": "podrán",
    },
    {
        "tipo": "cantidad",
        "name": "manifesto",
        "singular": "manifestó",
        "plural": "manifestaron",
    },
    {
        "tipo": "cantidad",
        "name": "identifico",
        "singular": "identificó",
        "plural": "identificarón",
    },
    // Tipo genero y cantidad 
    {
        "tipo": "generoCantidad",
        "name": "este",
        "singularM": "éste",
        "singularF": "ésta",
        "pluralM": "éstos",
        "pluralF": "éstas",
    },
    {
        "tipo": "generoCantidad",
        "name": "identificado",
        "singularM": "identificado",
        "singularF": "identificada",
        "pluralM": "identificados",
        "pluralF": "identificadas",
    },
    {
        "tipo": "generoCantidad",
        "name": "el",
        "singularM": "el",
        "singularF": "ella",
        "pluralM": "ellos",
        "pluralF": "ellas",
    },
    {
        "tipo": "generoCantidad",
        "name": "deudor",
        "singularM": "deudor",
        "singularF": "deudora",
        "pluralM": "deudores",
        "pluralF": "deudoras",
    },
    {
        "tipo": "generoCantidad",
        "name": "satisfecho",
        "singularM": "satisfecho",
        "singularF": "satisfecha",
        "pluralM": "satisfechos",
        "pluralF": "satisfechas",
    },
    {
        "tipo": "generoCantidad",
        "name": "solidario",
        "singularM": "solidario",
        "singularF": "solidaria",
        "pluralM": "solidarios",
        "pluralF": "solidarias",
    },
    {
        "tipo": "generoCantidad",
        "name": "mismo",
        "singularM": "mismo",
        "singularF": "misma",
        "pluralM": "mismos",
        "pluralF": "mismas",
    },
    {
        "tipo": "generoCantidad",
        "name": "domiciliado",
        "singularM": "domiciliado",
        "singularF": "domiciliada",
        "pluralM": "domiciliados",
        "pluralF": "domiciliadas",
    },
    {
        "tipo": "generoCantidad",
        "name": "expedido",
        "singularM": "expedido",
        "singularF": "expedida",
        "pluralM": "expedidos",
        "pluralF": "expedidas",
    },
    {
        "tipo": "generoCantidad",
        "name": "advertido",
        "singularM": "advertido",
        "singularF": "advertida",
        "pluralM": "advertidos",
        "pluralF": "advertidas",
    },
    {
        "tipo": "generoCantidad",
        "name": "propietario",
        "singularM": "propietario",
        "singularF": "propietaria",
        "pluralM": "propietarios",
        "pluralF": "propietarias",
    },
    {
        "tipo": "generoCantidad",
        "name": "notificado",
        "singularM": "notificado",
        "singularF": "notificada",
        "pluralM": "notificadas",
        "pluralF": "notificados",
    },
    {
        "tipo": "generoCantidad",
        "name": "elcomprador",
        "singularM": "EL COMPRADOR",
        "singularF": "LA COMPRADORA",
        "pluralM": "LOS COMPRADORES",
        "pluralF": "LAS COMPRADORAS",
    },
    {
        "tipo": "generoCantidad",
        "name": "garantizado",
        "singularM": "garantizado",
        "singularF": "garantizada",
        "pluralM": "garantizados",
        "pluralF": "garantizadas",
    },
    {
        "tipo": "generoCantidad",
        "name": "comprador",
        "singularM": "comprador",
        "singularF": "compradora",
        "pluralM": "compradores",
        "pluralF": "compradoras",
    },
    
];